<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :per-page="filter.take"
          :current-page="filter.page"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
          id="table-show-staff"
        >
          <template v-slot:cell(active)="{ item }">
            <span :class="item.active ? 'text-success' : 'text-error'">{{
              item
            }}</span>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <b-button
                variant="link"
                class="text-danger px-1 py-0"
                :id="'delete-branch-' + item.plant_id"
                @click="$emit('deleteBranch', item.id)"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  title="Delete"
                  class="main-color"
                />
              </b-button>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <span class="text-error" v-if="validate">
      Please Select At Least 1 Branch.
    </span>
    <Pagination :pageOptions="pageOptions" :filter="filter" :rows="rows" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    validate: {
      required: false,
    },
  },
  watch: {
    items(val) {
      return (this.items = val);
    },
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    chkBirthday(birthday) {
      let birthDate = birthday ? birthday.search("00:00:00") : -1;
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
</style>
